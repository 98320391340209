import React from "react"
import styles from "./BasicLayout.module.scss"
import { H1 } from "../../Typography/Headings/Headings"
import { Icon } from "../../Icon/Icons"

export const BasicLayout: React.FunctionComponent<{ title: string }> = ({
    children,
    title,
}) => {
    return (
        <main className={styles.layoutContainer}>
            <header>
                <span>
                    <H1>{title}</H1>
                    <Icon icon="haagse"></Icon>
                </span>
                <hr className={styles.horizontalBar} />
            </header>
            <section>{children}</section>
            <footer>
                <span>
                    Powered by <b>&nbsp;Unica</b>
                </span>
                <hr className={styles.horizontalBar} />
                <span>
                    More about the living lab{" "}
                    <span style={{width: 60}}>
                        <Icon icon="qr" />
                    </span>
                </span>
            </footer>
        </main>
    )
}
