import "./styles/base.scss"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Home from "./home"

const App: React.FunctionComponent = () => {
    return (
        <Router>
            <Routes>
                <Route index element={<Home />} />
            </Routes>
        </Router>
    )
}

ReactDOM.render(<App />, document.getElementById("root"))
