export interface spacesDataInterface {
    [space: string]: {
        occupancy?: string
        temp?: string
        co2?: string
        humidity?: string
        tables?: { [table: string]: string | undefined }
    }
}

export const updateData = async () => {
    return new Promise<spacesDataInterface>(async (resolve, reject) => {
        await fetch(
            `${process.env.REACT_APP_API_URL}/api/views/room/rooms/all`,
            {
                headers: {
                    authorization: process.env.REACT_APP_API_TOKEN ?? "",
                },
            }
        )
            .then((res) =>
                res.status !== 401
                    ? res
                    : Promise.reject(new Error("Unauthorized " + res.status))
            )
            .then(async (res) => await res.json())
            .then((data) => {
                resolve(data)
            })
            .catch((err) => reject(err))
    })
}
