import styles from "./DataCol.module.scss"
import { spacesDataInterface } from "../../../helpers/updateData"

export const DataCol: React.FunctionComponent<{
    data: spacesDataInterface
}> = ({ data }) => {
    let space = Object.keys(data)[0]
    let spaceObj = data[space]
    const simpleOccupance = (occupance: number) => {
        return {
            0: "Chill",
            1: "Lively",
            2: "Busy",
        }[occupance]
    }
    const inRange = (value: number, max: number, min?: number) => {
        if (value) {
            return min ? value >= min && value <= max : value <= max
        } else {
            return false
        }
    }
    return (
        <li className={styles.dataColumn}>
            <header>
                {space}
                <hr />
            </header>
            <span>
                {spaceObj.occupancy
                    ? !(parseInt(spaceObj.occupancy) < 0) &&
                      parseInt(spaceObj.occupancy) < 2
                        ? simpleOccupance(parseInt(spaceObj.occupancy) || 0)
                        : simpleOccupance(2)
                    : simpleOccupance(0)}
            </span>
            <span
                style={{
                    color: inRange(parseInt(spaceObj.temp ?? "0"), 22, 19)
                        ? "var(--white-primary)"
                        : "var(--red-primary)",
                }}
            >
                {spaceObj.temp &&
                    parseInt(spaceObj.temp) !== 0 &&
                    !(parseInt(spaceObj.temp) < 0) &&
                    Math.round(parseFloat(spaceObj.temp) * 10) / 10}
                &#8451;
            </span>
            <span
                style={{
                    color: inRange(parseInt(spaceObj.humidity ?? "0"), 60, 40)
                        ? "var(--white-primary)"
                        : "var(--red-primary)",
                }}
            >
                {spaceObj.humidity &&
                    parseInt(spaceObj.humidity) !== 0 &&
                    !(parseInt(spaceObj.humidity) < 0) &&
                    Math.round(parseFloat(spaceObj.humidity) * 10) / 10}
                %
            </span>
            <span
                style={{
                    color: inRange(parseInt(spaceObj.co2 ?? "0"), 1200)
                        ? "var(--white-primary)"
                        : "var(--red-primary)",
                }}
            >
                {spaceObj.co2 &&
                    parseInt(spaceObj.co2) !== 0 &&
                    !(parseInt(spaceObj.co2) < 0) &&
                    Math.round(parseInt(spaceObj.co2)) + " ppm"}
            </span>
        </li>
    )
}
