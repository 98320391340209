import { HTMLAttributes } from "react"
import { default as Icons } from "../../../../assets/icons/Icons.json"

export const Icon: React.FunctionComponent<{
    icon: keyof typeof Icons
    isHorizontal?: boolean
    customStyle?: any

}> = ({ icon, isHorizontal = true, customStyle = {} }) => {
    // const ratio = Icons[icon].size[0] / Icons[icon].size[1]

    return (
        <i
            style={{ width: "max-content", height: "max-content", ...customStyle } }
            dangerouslySetInnerHTML={{
                __html: Icons[icon]?.raw,
            }}
        />
    )
}
