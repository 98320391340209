import { BasicLayout } from "./domain/Core/components/Layouts/BasicLayout/BasicLayout"
import { HallPlan } from "./domain/Core/components/HallPlan/HallPlan"

const Home = () => {
    return (
        <BasicLayout title="Occupancy and climate">
            <HallPlan />
        </BasicLayout>
    )
}

export default Home
