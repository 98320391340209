import { useState, useEffect } from "react"
import styles from "./HallPlan.module.scss"
// import { OccupyColor } from "./HallPlanTypes"
import { Table } from "../Table/Table"
import { Legend } from "../Legend/Legend"
import { spacesDataInterface, updateData } from "../../helpers/updateData"

export const HallPlan = () => {
    const [currentData, setCurrentData] = useState<
        spacesDataInterface | undefined
    >(undefined)
    const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms))
    const refreshInterval: number =
        parseInt(process.env.REACT_APP_API_REFRESH_INTERVAL_SECONDS ?? "") || 30

    const colorOccupance = (occupance?: number) => {
        return {
            0: "var(--white-primary)",
            1: "var(--blue-quinary)",
            2: "var(--blue-tertiary)",
            3: "var(--blue-primary)",
        }[occupance && occupance > 0 ? (occupance < 3 ? occupance : 2) : 0]
    }

    const inRange = (value: number, max: number, min?: number) => {
        return min ? value >= min && value <= max : value <= max
    }

    const colorOccupanceToilet = (occupancy: number) => {
        return {
            0: "var(--white-primary)",
            1: "var(--blue-quinary)",
            2: "var(--blue-tertiary)",
            3: "var(--blue-primary)",
        }[occupancy < 3 ? 0 : occupancy < 6 ? 1 : occupancy < 10 ? 2 : 3]
    }

    const occupanceFromTables = (
        tables: { [table: string]: string | undefined } | undefined
    ) => {
        if (tables === undefined) {
            return "var(--blue-quinary)"
        }
        let keys = Object.keys(tables)
        let occupied = keys
            .map((k) => tables[k])
            .reduce(
                (count, k) => (parseInt(k ?? "0") > 0 ? count + 1 : count),
                0
            )
        let part = keys.length / 2
        return {
            0: "var(--blue-quinary)",
            1: "var(--blue-tertiary)",
            2: "var(--blue-primary)",
        }[
            occupied < keys.length
                ? [...Array(2)]
                      .map((_, i) =>
                          [...Array(part * (i + 1)).keys()].splice(
                              part * (i + 1) - part,
                              part * (i + 1)
                          )
                      )
                      .findIndex((e) => e.includes(Number(occupied) ?? 0)) + 1
                : 2
        ]
    }

    useEffect(() => {
        let alive = true
        ;(async () => {
            while (alive) {
                await updateData()
                    .then((data) => {
                        setCurrentData(data)
                    })
                    .catch((err) => {
                        console.log(err)
                        alive = false
                    })
                await delay(refreshInterval * 1000)
            }
        })()
        return () => {
            alive = false
        }
    }, [])

    return (
        <>
            {currentData && <Table columnsData={currentData} />}

            <article className={styles.planContainer}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={1167}
                    height={504}
                    viewBox="0 0 1167 504"
                >
                    <defs>
                        <style>
                            {
                                ".cls-1{fill:#fff}.cls-1,.cls-11,.cls-16,.cls-4,.cls-5,.cls-6{fill-rule:evenodd}.cls-3{fill:none}.cls-4{fill:#9c9ea1}.cls-13,.cls-5{fill:#e5f7fa}.cls-10,.cls-18,.cls-6{fill:#223343}.cls-12{fill:#73d7e8}.cls-10,.cls-18,.cls-9{isolation:isolate}.cls-10{font-size:12px;font-family:AktivGroteskCorp-Medium,Aktiv Grotesk Corp}.cls-10,.cls-18{font-weight:500}.cls-11{fill:#e8eaec}.cls-14{fill:#b2e8f2}.cls-15{fill:#00b5d4}.cls-16{fill:#223343}.cls-18{font-size:18px;font-family:GTWalsheimPro-Medium,GT Walsheim Pro}"
                            }
                        </style>
                    </defs>
                    <g id="Finals">
                        <g
                            id="Occupancy_Climate-slide1"
                            data-name="Occupancy+Climate-slide1"
                        >
                            <g id="PLAN">
                                <g id="static-img-plan-bg">
                                    <path
                                        id="plan-bg"
                                        className="cls-1"
                                        style={{
                                            fill: "#4e5c69",
                                            fillRule: "evenodd",
                                        }}
                                        d="M27.21 362.85v-7.7H3V242.32h627.88l-13.21-22.02 123.81-64.94L1002.32 4l135.37 219.05-28.07 15.97 50.63 127.13-9.36 3.86 12.11 52.84-62.18 45.13-55.03 22.01L939.58 501H3v-83.11h24.21v-8.81"
                                    />
                                    <path
                                        id="grayed-out"
                                        d="M109.76 418.44V501H3v-82.56Zm515.06-24.22v25.32H736V501H329.87v-81.46h107.85v-2.75h50.08v2.75h34.67v-25.32Zm454.54-265.28 58.33 94.11-28.07 16 50.63 127.14-9.36 3.85 12.11 52.8-62.18 45.16-55 22-105.14 11h-91.34v-81.46h90.8v-1.1h-19.27v-61.09h-48.42v-31.92h48.42V239h73.74v-58.33ZM469 355v33h-80v-33ZM98.75 242.32V355.7H3V242.32Z"
                                        style={{
                                            fill: "#4e5c69",
                                            fillRule: "evenodd",
                                        }}
                                    />
                                    <g id="grayed-room-icons">
                                        <g id="room_room" data-name="room/room">
                                            <path
                                                id="room-area"
                                                className="cls-3"
                                                d="M389 355.5h79v32h-79z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-stair"
                                                    className="cls-4"
                                                    d="M441 380.81a.5.5 0 0 1-.5.5h-24a.5.5 0 0 1-.5-.5v-24a.5.5 0 0 1 .5-.5h6.09a.5.5 0 0 1 .41.49v2.5h5.59a.5.5 0 0 1 .41.49v2.5h5.59a.5.5 0 0 1 .41.49v2.5h5.5a.5.5 0 0 1 .5.5Zm-1-12.5h-5v2.5a.5.5 0 0 1-.41.49H429v2.5a.5.5 0 0 1-.41.49H423v2.5a.5.5 0 0 1-.41.49H417v3h23Zm-18-11h-5v19h5v-2.5a.5.5 0 0 1 .41-.49H428v-2.5a.5.5 0 0 1 .41-.49H434v-2.5a.5.5 0 0 1 .41-.49H440v-1h-5.59a.5.5 0 0 1-.41-.49v-2.5h-5.59a.5.5 0 0 1-.41-.49v-2.5h-5.59a.5.5 0 0 1-.41-.49Z"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="room_room-2"
                                            data-name="room/room"
                                        >
                                            <path
                                                id="room-area-2"
                                                data-name="room-area"
                                                className="cls-3"
                                                d="M5 244.5h93v110H5z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage-2"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-storage"
                                                    className="cls-4"
                                                    d="M63.5 287.5a.5.5 0 0 1 .5.5v24a.5.5 0 0 1-.41.49H39.5a.5.5 0 0 1-.5-.5V288a.5.5 0 0 1 .5-.5Zm-.5 17H40v7h2.63a1.06 1.06 0 0 1-.13-.5v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4a1.06 1.06 0 0 1-.13.5H63Zm0-8H40v7h8.63a1.06 1.06 0 0 1-.13-.5v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4a1.06 1.06 0 0 1-.13.5H63Zm0-8H40v7h2.63a1.06 1.06 0 0 1-.13-.5v-4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1.06 1.06 0 0 1-.13.5h2.26a1.06 1.06 0 0 1-.13-.5v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1.06 1.06 0 0 1-.13.5H63Z"
                                                />
                                            </g>
                                            <g
                                                id="room_number"
                                                data-name="room/number"
                                            >
                                                <path
                                                    id="Rectangle"
                                                    className="cls-3"
                                                    d="M5 244.5h41v26H5z"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="room_room-3"
                                            data-name="room/room"
                                        >
                                            <path
                                                id="room-area-3"
                                                data-name="room-area"
                                                className="cls-3"
                                                d="M922 238.5h219v251H922z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage-3"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-private"
                                                    className="cls-4"
                                                    d="M1021.93 358.82v.14l1.63 7.81h7.45a.51.51 0 0 1 .5.41v3.93h1.94a1.49 1.49 0 0 1 1.49 1.35v.15a1.49 1.49 0 0 1-1.35 1.49H1028v4.36l5.17 3.3h9.83v-14h-6.72a.5.5 0 0 1-.49-.41v-.09a.5.5 0 0 1 .41-.49h7.31a.5.5 0 0 1 .49.41v15.09a.5.5 0 0 1-.41.49h-24.09a.5.5 0 0 1-.5-.5.51.51 0 0 1 .41-.5h2.45l5.11-3.26v-4.4h-3.68a1.5 1.5 0 0 1-1.43-1.06v-.14l-2.8-13.33a1.5 1.5 0 0 1 2.9-.76Zm5 20.88-3.25 2.07h3.32Zm1 0v2.11h3.31Zm2.58-8.55v-3.34h-6.74l.7 3.34Z"
                                                />
                                            </g>
                                            <g
                                                id="room_number-2"
                                                data-name="room/number"
                                            >
                                                <path
                                                    id="Rectangle-2"
                                                    data-name="Rectangle"
                                                    className="cls-3"
                                                    d="M922 238.5h41v26h-41z"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="room_room-4"
                                            data-name="room/room"
                                        >
                                            <path
                                                id="room-area-4"
                                                data-name="room-area"
                                                className="cls-3"
                                                d="M389 420.5h347v78H389z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage-4"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-private-2"
                                                    data-name="i-r-private"
                                                    className="cls-4"
                                                    d="M552.93 447.24v.14l1.63 7.81h7.45a.51.51 0 0 1 .5.41v3.93h1.94a1.49 1.49 0 0 1 1.49 1.35v.12a1.49 1.49 0 0 1-1.35 1.49H559v4.37l5.17 3.29H574v-14h-6.72a.5.5 0 0 1-.49-.41v-.09a.5.5 0 0 1 .41-.49h7.31a.5.5 0 0 1 .49.41v15.09a.5.5 0 0 1-.41.49H550.5a.5.5 0 0 1-.09-1h2.45l5.11-3.25v-4.41h-3.68a1.5 1.5 0 0 1-1.43-1.06v-.13L550 448a1.5 1.5 0 0 1 2.9-.76Zm5 20.88-3.25 2.07H558Zm1 0v2.11h3.31Zm2.58-8.55v-3.34h-6.74l.7 3.34Z"
                                                />
                                            </g>
                                            <g
                                                id="room_number-3"
                                                data-name="room/number"
                                            >
                                                <path
                                                    id="Rectangle-3"
                                                    data-name="Rectangle"
                                                    className="cls-3"
                                                    d="M389 420.5h41v26h-41z"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="room_room-5"
                                            data-name="room/room"
                                        >
                                            <path
                                                id="room-area-5"
                                                data-name="room-area"
                                                className="cls-3"
                                                d="M5 420.5h105v78H5z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage-5"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-stair-2"
                                                    data-name="i-r-stair"
                                                    className="cls-4"
                                                    d="M70 470.69a.5.5 0 0 1-.5.5h-24a.5.5 0 0 1-.5-.5v-24a.5.5 0 0 1 .5-.5h6.09a.5.5 0 0 1 .41.49v2.5h5.59a.5.5 0 0 1 .41.49v2.5h5.59a.5.5 0 0 1 .41.49v2.5h5.5a.5.5 0 0 1 .5.5Zm-1-12.5h-5v2.5a.5.5 0 0 1-.41.49H58v2.5a.5.5 0 0 1-.41.49H52v2.5a.5.5 0 0 1-.41.49H46v3h23Zm-18-11h-5v19h5v-2.5a.5.5 0 0 1 .41-.49H57v-2.5a.5.5 0 0 1 .41-.49H63v-2.5a.5.5 0 0 1 .41-.49H69v-1h-5.59a.5.5 0 0 1-.41-.49v-2.5h-5.59a.5.5 0 0 1-.41-.49v-2.5h-5.59a.5.5 0 0 1-.41-.49Z"
                                                />
                                            </g>
                                            <g
                                                id="room_number-4"
                                                data-name="room/number"
                                            >
                                                <path
                                                    id="Rectangle-4"
                                                    data-name="Rectangle"
                                                    className="cls-3"
                                                    d="M5 420.5h41v26H5z"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="room_room-6"
                                            data-name="room/room"
                                        >
                                            <path
                                                id="room-area-6"
                                                data-name="room-area"
                                                className="cls-3"
                                                d="M850 420.5h89v78h-89z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage-6"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-stair-3"
                                                    data-name="i-r-stair"
                                                    className="cls-4"
                                                    d="M907 470.69a.5.5 0 0 1-.5.5h-24a.5.5 0 0 1-.5-.5v-24a.5.5 0 0 1 .5-.5h6.09a.5.5 0 0 1 .41.49v2.5h5.59a.5.5 0 0 1 .41.49v2.5h5.59a.5.5 0 0 1 .41.49v2.5h5.5a.5.5 0 0 1 .5.5Zm-1-12.5h-5v2.5a.5.5 0 0 1-.41.49H895v2.5a.5.5 0 0 1-.41.49H889v2.5a.5.5 0 0 1-.41.49H883v3h23Zm-18-11h-5v19h5v-2.5a.5.5 0 0 1 .41-.49H894v-2.5a.5.5 0 0 1 .41-.49H900v-2.5a.5.5 0 0 1 .41-.49H906v-1h-5.59a.5.5 0 0 1-.41-.49v-2.5h-5.59a.5.5 0 0 1-.41-.49v-2.5h-5.59a.5.5 0 0 1-.41-.49Z"
                                                />
                                            </g>
                                            <g
                                                id="room_number-5"
                                                data-name="room/number"
                                            >
                                                <path
                                                    id="Rectangle-5"
                                                    data-name="Rectangle"
                                                    className="cls-3"
                                                    d="M850 420.5h41v26h-41z"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="room_room-7"
                                            data-name="room/room"
                                        >
                                            <path
                                                id="room-area-7"
                                                data-name="room-area"
                                                className="cls-3"
                                                d="M1015 159.5h89v78h-89z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage-7"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-hall"
                                                    className="cls-4"
                                                    d="M1072 186.61v23.08a.59.59 0 0 1 0 .23h-.38l-7.88-4.92h-7.72l-7.88 4.92h-.36v-.05l-.05-.06a.42.42 0 0 1-.06-.14v-22.98a.51.51 0 0 1 .65-.48h.07l5 2.5a.52.52 0 0 1 .27.36v16.56l2.24-1.39a.36.36 0 0 1 .17-.07h.09v-14a.5.5 0 0 1 .41-.49h6.43a.5.5 0 0 1 .49.41v14.09a.39.39 0 0 1 .18 0h.08l2.24 1.39V189.1a.51.51 0 0 1 .19-.31h.08l5-2.5h.07a.5.5 0 0 1 .67.32Zm-24 .89v21.29l4-2.5v-7.09l-.41.07a.5.5 0 0 1-.17-1l.58-.11v-8.66Zm23 0-4 2v16.79l4 2.5v-9.1l-.58-.1a.49.49 0 0 1-.41-.49v-.1a.48.48 0 0 1 .57-.4l.41.07Zm-8.5 3.19h-6v13.5h6v-5h-1.7a.5.5 0 0 1 0-1h1.7Z"
                                                />
                                            </g>
                                            <g
                                                id="room_number-6"
                                                data-name="room/number"
                                            >
                                                <path
                                                    id="Rectangle-6"
                                                    data-name="Rectangle"
                                                    className="cls-3"
                                                    d="M1015 159.5h41v26h-41z"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="room_room-8"
                                            data-name="room/room"
                                        >
                                            <path
                                                id="room-area-8"
                                                data-name="room-area"
                                                className="cls-3"
                                                d="M330 421.5h58v78h-58z"
                                            />
                                            <g
                                                id="Icons_Roomtype_r-storage-8"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-reception"
                                                    className="cls-4"
                                                    d="M371 447.19a.5.5 0 0 1 .5.5v24a.5.5 0 0 1-.41.49H347a.5.5 0 0 1-.5-.5v-24a.5.5 0 0 1 .5-.5Zm-.5 15h-23v9h23Zm-18-3v-.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v.5a2 2 0 0 1 2 1.85v.15h4v-3a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v3h3v-13h-23v13h3a2 2 0 0 1 2-2Zm11.51-7a2 2 0 1 1-2 2 2 2 0 0 1 1.99-2Z"
                                                />
                                            </g>
                                            <g
                                                id="room_number-7"
                                                data-name="room/number"
                                            >
                                                <path
                                                    id="Rectangle-7"
                                                    data-name="Rectangle"
                                                    className="cls-3"
                                                    d="M330 421.5h41v26h-41z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="hallway">
                                    <path
                                        style={{
                                            fill: "var(--white-primary)",
                                        }}
                                        className="cls-5"
                                        d="M29 356v61.5h82.5V500l218.5-1v-80h107.5l.08-2.67 50.92.17v2.5h34v-25h103v24.5h112v3.5H763v-4l55.31.02.19 3.98h30v-4H921v-59.5l-49.5-.5V240h-47v116H469v33h-80v-33H29z"
                                    />
                                    <circle
                                        cx="255"
                                        cy="438.5"
                                        r="25"
                                        fill="var(--red-primary)"
                                    />
                                    <text
                                        x="255"
                                        y="435.5"
                                        textAnchor="middle"
                                        fill="var(--white-primary)"
                                        alignmentBaseline="middle"
                                        fontSize="10"
                                    >
                                        You are
                                    </text>
                                    <text
                                        x="255"
                                        y="446.5"
                                        textAnchor="middle"
                                        fill="var(--white-primary)"
                                        alignmentBaseline="middle"
                                        fontSize="10"
                                    >
                                        here
                                    </text>
                                    <g
                                        id="room_sensors"
                                        data-name="room/sensors"
                                    >
                                        <g
                                            id="Icons_Sensor-type_s-tem"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="i-s-temperature"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.Hall
                                                                .temp ?? "0"
                                                        ),
                                                        22,
                                                        19
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M125.52 480a.72.72 0 0 1 .72.72v1.2h4.08a.24.24 0 1 1 0 .48h-4.08v1h2.64a.24.24 0 1 1 0 .48h-2.64v4a2.17 2.17 0 0 1 1.44 1.94v.1a2.16 2.16 0 1 1-2.88-2v-7.08a.72.72 0 0 1 .72-.84Z"
                                            />
                                        </g>
                                        <g
                                            id="Icons_Sensor-type_s-tem-2"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="Combined-Shape"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.Hall
                                                                .humidity ?? "0"
                                                        ),
                                                        60,
                                                        40
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M141.1 484.86a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Zm5.28-4.8a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g id="wc-wc-left">
                                    <path
                                        id="WC-left"
                                        fill={colorOccupanceToilet(
                                            parseInt(
                                                currentData?.Dames.occupancy ??
                                                    "0"
                                            )
                                        )}
                                        d="M737 423.8h28.03L765 420h24v80h-52v-76.2z"
                                    />
                                    <g id="wc-left-2" data-name="wc-left">
                                        <path
                                            // style={{
                                            //     fill: OccupyColor(
                                            //         currentPlanObject.wc.left
                                            //             .people
                                            //     ),
                                            // }}
                                            id="room-area-9"
                                            data-name="room-area"
                                            className="cls-3"
                                            d="M737 422h54v78h-54z"
                                        />
                                        <g id="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-9"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-restroom"
                                                    className="cls-6"
                                                    d="M776 447.69a.5.5 0 0 1 .5.5v24a.5.5 0 0 1-.5.5h-24a.5.5 0 0 1-.5-.5v-24a.5.5 0 0 1 .5-.5Zm-.5 1h-23v23h23Zm-12.74 9a1 1 0 0 1 1 1 1.64 1.64 0 0 1 0 .22l-1.76 8a1 1 0 0 1-1 .78h-2a1 1 0 0 1-1-.78l-1.76-8a1 1 0 0 1 .77-1.2h.21Zm6.24 0a1 1 0 0 1 1 .79l1.76 8a1.49 1.49 0 0 1 0 .21 1 1 0 0 1-1 1h-5.72a1 1 0 0 1-.77-1.19l1.76-8a1 1 0 0 1 1-.79h2Zm-9-5a2 2 0 1 1-2 2 2 2 0 0 1 2-2Zm8 0a2 2 0 1 1-2 2 2 2 0 0 1 2-2Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="wc-wc-right">
                                    <path
                                        id="WC-right"
                                        fill={colorOccupanceToilet(
                                            parseInt(
                                                currentData?.Heren.occupancy ??
                                                    "0"
                                            )
                                        )}
                                        d="M849 423.8h-32.03l.03-3.8h-26v80h58v-76.2z"
                                    />
                                    <g id="wc-right-2" data-name="wc-right">
                                        <path
                                            // style={{
                                            //     fill: OccupyColor(
                                            //         currentPlanObject.wc.right
                                            //             .people
                                            //     ),
                                            // }}
                                            id="room-area-10"
                                            data-name="room-area"
                                            className="cls-3"
                                            d="M791 422h57v78h-57z"
                                        />
                                        <g id="Group-3" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-10"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="i-r-restroom-2"
                                                    data-name="i-r-restroom"
                                                    className="cls-6"
                                                    d="M831.5 447.69a.5.5 0 0 1 .5.5v24a.5.5 0 0 1-.5.5h-24a.5.5 0 0 1-.5-.5v-24a.5.5 0 0 1 .5-.5Zm-.5 1h-23v23h23Zm-12.74 9a1 1 0 0 1 1 1 1.64 1.64 0 0 1 0 .22l-1.76 8a1 1 0 0 1-1 .78h-2a1 1 0 0 1-1-.78l-1.76-8a1 1 0 0 1 .77-1.2h.21Zm6.24 0a1 1 0 0 1 1 .79l1.76 8a1.49 1.49 0 0 1 0 .21 1 1 0 0 1-1 1h-5.72a1 1 0 0 1-.77-1.19l1.76-8a1 1 0 0 1 1-.79h2Zm-9-5a2 2 0 1 1-2 2 2 2 0 0 1 2.01-2Zm8 0a2 2 0 1 1-2 2 2 2 0 0 1 2.01-2Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="little-12">
                                    <path
                                        id="_12"
                                        data-name={12}
                                        fill={colorOccupance(
                                            parseInt(
                                                currentData?.["0.12"]
                                                    .occupancy ?? "0"
                                            )
                                        )}
                                        d="M873 283h48v42h-48z"
                                    />
                                    <g id="Group-5" data-name="Group">
                                        <g id="n12">
                                            <path
                                                id="Rectangle-8"
                                                data-name="Rectangle"
                                                className="cls-3"
                                                d="M873.5 283.5h17v41h-17z"
                                            />
                                            <g
                                                id="_0.36"
                                                data-name={0.36}
                                                className="cls-9"
                                            >
                                                <text
                                                    className="cls-10"
                                                    transform="rotate(-90 601.02 -284.98)"
                                                >
                                                    {"0.12"}
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area" data-name="room/area">
                                        <circle
                                            id="Oval"
                                            className="cls-3"
                                            cx={903.5}
                                            cy={304}
                                            r={21.5}
                                        />
                                        <g id="Group-6" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-11"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-2"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M891.5 292.5a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H914l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49l-2.47-14.51h-18l-2.42 14.5a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44V308l1.94-15.5Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="little-14">
                                    <path
                                        id="_14"
                                        data-name={14}
                                        fill={colorOccupance(
                                            parseInt(
                                                currentData?.["0.12"]
                                                    .occupancy ?? "0"
                                            )
                                        )}
                                        d="M873 240h48v42h-48z"
                                    />
                                    <g id="Group-8" data-name="Group">
                                        <g id="n14">
                                            <path
                                                id="Rectangle-9"
                                                data-name="Rectangle"
                                                className="cls-3"
                                                d="M873.5 240.5h17v41h-17z"
                                            />
                                            <g
                                                id="_0.36-2"
                                                data-name={0.36}
                                                className="cls-9"
                                            >
                                                <text
                                                    className="cls-10"
                                                    transform="rotate(-90 579.52 -306.48)"
                                                >
                                                    {"0.14"}
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-2" data-name="room/area">
                                        <circle
                                            id="Oval-2"
                                            data-name="Oval"
                                            className="cls-3"
                                            cx={903.5}
                                            cy={262}
                                            r={21.5}
                                        />
                                        <g id="Group-9" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-12"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-3"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M891.5 250.5a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H914l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49l-2.47-14.51h-18l-2.42 14.5a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44V266l1.94-15.5Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="little-16">
                                    <path
                                        id="_16"
                                        data-name={16}
                                        fill={colorOccupance(
                                            parseInt(
                                                currentData?.["0.12"]
                                                    .occupancy ?? "0"
                                            )
                                        )}
                                        d="M770 322.5h54V355h-54z"
                                    />
                                    <g id="Group-11" data-name="Group">
                                        <g id="n16">
                                            <path
                                                id="Rectangle-10"
                                                data-name="Rectangle"
                                                className="cls-3"
                                                d="M767.5 317.5h26v41h-26z"
                                            />
                                            <g
                                                id="_0.36-3"
                                                data-name={0.36}
                                                className="cls-9"
                                            >
                                                <text
                                                    className="cls-10"
                                                    transform="rotate(-90 567.27 -217.23)"
                                                >
                                                    {"0.16"}
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-3" data-name="room/area">
                                        <circle
                                            id="Oval-3"
                                            data-name="Oval"
                                            className="cls-3"
                                            cx={803.5}
                                            cy={339.5}
                                            r={21.5}
                                        />
                                        <g id="Group-12" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-13"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-4"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M791.5 328a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H814l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L812.5 329h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L793 328Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="openspace-18">
                                    <polygon
                                        id="_x31_8-bg"
                                        fill="var(--white-primary)"
                                        points="619.8,219.7 740.8,155.7 824.8,238.7 824.8,320.2 769.8,320.2 769.8,353.9 
                                        552.2,353.9 552.2,243.7 634.2,243.7 				"
                                    />

                                    <g id="n18">
                                        <path
                                            id="Rectangle-11"
                                            data-name="Rectangle"
                                            className="cls-3"
                                            d="M553 245h41v26h-41z"
                                        />
                                        <g
                                            id="_0.36-4"
                                            data-name={0.36}
                                            className="cls-9"
                                        >
                                            <text
                                                className="cls-10"
                                                transform="translate(561.46 262)"
                                            >
                                                {"0.18"}
                                            </text>
                                        </g>
                                    </g>
                                    <g
                                        id="Icons_Sensor-type_s-tem-3"
                                        data-name="Icons/Sensor-type/s-tem"
                                    >
                                        <path
                                            id="i-s-temperature-2"
                                            data-name="i-s-temperature"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.18"]
                                                            .temp ?? "0"
                                                    ),
                                                    22,
                                                    19
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M566.52 334a.72.72 0 0 1 .72.72v1.2h4.08a.24.24 0 0 1 0 .48h-4.08v1h2.64a.24.24 0 1 1 0 .48h-2.64v4a2.17 2.17 0 0 1 1.44 1.94v.1a2.16 2.16 0 1 1-2.88-2v-7.08a.72.72 0 0 1 .72-.84Z"
                                        />
                                        <path
                                            id="Combined-Shape-5"
                                            data-name="Combined-Shape"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.18"]
                                                            .humidity ?? "0"
                                                    ),
                                                    60,
                                                    40
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M582.1 338.86a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 0 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Zm5.28-4.8a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25s.02.01.04 0Z"
                                        />
                                        <path
                                            id="i-s-co2"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.18"]
                                                            .co2 ?? "0"
                                                    ),
                                                    1200
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M607.83 341.81a1.09 1.09 0 0 1 .58.14 1 1 0 0 1 .38.38 1.12 1.12 0 0 1 .13.55 1.29 1.29 0 0 1-.07.43 2.3 2.3 0 0 1-.15.32l-.06.1-.1.14-.14.17-.07.09-.64.67h1.08a.28.28 0 0 1 .15.08.28.28 0 0 1 .08.2.27.27 0 0 1-.08.19.21.21 0 0 1-.15.07H607a.21.21 0 0 1-.15-.07.3.3 0 0 1-.07-.21.31.31 0 0 1 0-.18l1.08-1.16.09-.1a1.21 1.21 0 0 0 .2-.3.82.82 0 0 0 .11-.37.53.53 0 0 0-.07-.28.64.64 0 0 0-.2-.2.47.47 0 0 0-.26-.08.59.59 0 0 0-.29.1 1.1 1.1 0 0 0-.27.26.27.27 0 0 1-.12.1h-.11a.32.32 0 0 1-.2-.08.26.26 0 0 1 0-.36 1.77 1.77 0 0 1 .14-.17 1.71 1.71 0 0 1 .44-.3 1.08 1.08 0 0 1 .51-.13Zm-8-4.58a2.61 2.61 0 0 1 .69.08 2.8 2.8 0 0 1 .63.23.41.41 0 0 1 .24.33.46.46 0 0 1-.11.4.35.35 0 0 1-.27.16.57.57 0 0 1-.26 0h-.07a1.9 1.9 0 0 0-.4-.14 2.22 2.22 0 0 0-.45-.05 2.11 2.11 0 0 0-.77.14 1.65 1.65 0 0 0-.58.39 1.62 1.62 0 0 0-.36.59 2.29 2.29 0 0 0-.12.76 2.23 2.23 0 0 0 .14.85 1.54 1.54 0 0 0 .38.59 1.62 1.62 0 0 0 .59.36 2.09 2.09 0 0 0 .72.12 3 3 0 0 0 .43 0 1.5 1.5 0 0 0 .42-.15.55.55 0 0 1 .33-.05.43.43 0 0 1 .28.16.5.5 0 0 1 .11.41.41.41 0 0 1-.24.31 2.26 2.26 0 0 1-.43.17 3.54 3.54 0 0 1-.44.11 3.44 3.44 0 0 1-.46 0 3.32 3.32 0 0 1-1.09-.18 2.62 2.62 0 0 1-.91-.54 2.58 2.58 0 0 1-.64-.9 3.24 3.24 0 0 1-.23-1.25 3 3 0 0 1 .21-1.13 2.54 2.54 0 0 1 .6-.9 2.8 2.8 0 0 1 .91-.59 3.09 3.09 0 0 1 1.19-.28Zm4.34 0a2.62 2.62 0 0 1 1.09.22 2.67 2.67 0 0 1 .86.62 2.83 2.83 0 0 1 .56.91 3.24 3.24 0 0 1 0 2.24 2.83 2.83 0 0 1-.56.91 2.67 2.67 0 0 1-.86.62 2.81 2.81 0 0 1-2.18 0 2.74 2.74 0 0 1-.85-.62 3 3 0 0 1-.57-.91 3.24 3.24 0 0 1 0-2.24 3 3 0 0 1 .57-.91 2.74 2.74 0 0 1 .85-.62 2.62 2.62 0 0 1 1.13-.26Zm0 .95a1.51 1.51 0 0 0-.87.26 1.73 1.73 0 0 0-.59.69 2.16 2.16 0 0 0-.21 1 2.19 2.19 0 0 0 .21 1 1.73 1.73 0 0 0 .59.69 1.59 1.59 0 0 0 1.74 0 1.73 1.73 0 0 0 .59-.69 2.25 2.25 0 0 0 0-1.94 1.73 1.73 0 0 0-.59-.69 1.51 1.51 0 0 0-.83-.36Z"
                                        />
                                    </g>
                                    <g id="room_area-4" data-name="room/area">
                                        <circle
                                            id="Oval-4"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.18"].tables
                                                        ?.Table1 ?? "0"
                                                )
                                            )}
                                            cx={612.5}
                                            cy={273.5}
                                            r={21.5}
                                        />
                                        <g id="Group-14" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-14"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-6"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M600.5 262a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H623l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L621.5 263h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L602 262Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-5" data-name="room/area">
                                        <circle
                                            id="Oval-5"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.18"].tables
                                                        ?.Table4 ?? "0"
                                                )
                                            )}
                                            cx={717.5}
                                            cy={203.5}
                                            r={21.5}
                                        />
                                        <g id="Group-16" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-15"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-7"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M705.5 192a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H728l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L726.5 193h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L707 192Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-6" data-name="room/area">
                                        <circle
                                            id="Oval-6"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.18"].tables
                                                        ?.Table3 ?? "0"
                                                )
                                            )}
                                            cx={684.5}
                                            cy={263.5}
                                            r={21.5}
                                        />
                                        <g id="Group-18" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-16"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-8"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M672.5 252a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H695l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L693.5 253h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L674 252Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-7" data-name="room/area">
                                        <circle
                                            id="Oval-7"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.18"].tables
                                                        ?.Table6 ?? "0"
                                                )
                                            )}
                                            cx={772.5}
                                            cy={267.5}
                                            r={21.5}
                                        />
                                        <g id="Group-20" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-17"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-9"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M760.5 256a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H783l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L781.5 257h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L762 256Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-8" data-name="room/area">
                                        <circle
                                            id="Oval-8"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.18"].tables
                                                        ?.Table5 ?? "0"
                                                )
                                            )}
                                            cx={728.5}
                                            cy={324.3}
                                            r={21.5}
                                        />
                                        <g id="Group-22" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-18"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-10"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M716.5 312.8a.5.5 0 0 1-.5-.5.5.5 0 0 1 .41-.49h24.09a.5.5 0 0 1 .5.5.5.5 0 0 1-.41.49H739l1.94 15.5a.44.44 0 0 1-.39.49.59.59 0 0 1-.58-.49l-2.47-14.5h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06l1.94-15.5Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-9" data-name="room/area">
                                        <circle
                                            id="Oval-9"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.18"].tables
                                                        ?.Table2 ?? "0"
                                                )
                                            )}
                                            cx={643.5}
                                            cy={316.5}
                                            r={21.5}
                                        />
                                        <g id="Group-24" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-19"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-11"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M631.5 305a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H654l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L652.5 306h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L633 305Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="openspace-10">
                                    <polygon
                                        id="_x31_0-bg"
                                        fill="var(--white-primary)"
                                        points="1002.3,3.9 740.3,155.9 823.3,237.9 994.3,237.9 994.3,180.4 1079.3,128.9 				"
                                    />

                                    <g id="n10">
                                        <path
                                            id="Rectangle-12"
                                            data-name="Rectangle"
                                            className="cls-3"
                                            d="M953 212h41v26h-41z"
                                        />
                                        <g
                                            id="_0.36-5"
                                            data-name={0.36}
                                            className="cls-9"
                                        >
                                            <text
                                                className="cls-10"
                                                transform="translate(961.46 229)"
                                            >
                                                {"0.10"}
                                            </text>
                                        </g>
                                    </g>
                                    <g
                                        id="room_sensors-2"
                                        data-name="room/sensors"
                                    >
                                        <g
                                            id="Icons_Sensor-type_s-tem-4"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="i-s-temperature-3"
                                                data-name="i-s-temperature"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.10"
                                                            ].temp ?? "0"
                                                        ),
                                                        22,
                                                        19
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M895.52 219a.72.72 0 0 1 .72.72v1.2h4.08a.24.24 0 0 1 0 .48h-4.08v1h2.64a.24.24 0 0 1 0 .48h-2.64v4a2.17 2.17 0 0 1 1.44 1.94v.1a2.16 2.16 0 1 1-2.88-2v-7.08a.72.72 0 0 1 .72-.84Z"
                                            />
                                        </g>
                                        <g
                                            id="Icons_Sensor-type_s-tem-5"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="Combined-Shape-12"
                                                data-name="Combined-Shape"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.10"
                                                            ].humidity ?? "0"
                                                        ),
                                                        60,
                                                        40
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M911.1 223.86a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 0 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Zm5.28-4.8a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 0 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Z"
                                            />
                                        </g>
                                        <g
                                            id="Icons_Sensor-type_s-tem-6"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="i-s-co2-2"
                                                data-name="i-s-co2"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.10"
                                                            ].co2 ?? "0"
                                                        ),
                                                        1200
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M936.83 226.81a1.09 1.09 0 0 1 .58.14 1 1 0 0 1 .38.38 1.12 1.12 0 0 1 .13.55 1.29 1.29 0 0 1-.07.43 2.3 2.3 0 0 1-.15.32l-.06.1-.1.14-.14.17-.07.09-.64.67h1.08a.28.28 0 0 1 .15.08.28.28 0 0 1 .08.2.27.27 0 0 1-.08.19.21.21 0 0 1-.15.07H936a.21.21 0 0 1-.15-.07.3.3 0 0 1-.07-.21.31.31 0 0 1 0-.18l1.08-1.16.09-.1a1.21 1.21 0 0 0 .2-.3.82.82 0 0 0 .11-.37.53.53 0 0 0-.07-.28.64.64 0 0 0-.2-.2.47.47 0 0 0-.26-.08.59.59 0 0 0-.29.1 1.1 1.1 0 0 0-.27.26.27.27 0 0 1-.12.1h-.11a.32.32 0 0 1-.2-.08.26.26 0 0 1 0-.36 1.77 1.77 0 0 1 .14-.17 1.71 1.71 0 0 1 .44-.3 1.08 1.08 0 0 1 .51-.13Zm-8-4.58a2.61 2.61 0 0 1 .69.08 2.8 2.8 0 0 1 .63.23.41.41 0 0 1 .24.33.46.46 0 0 1-.11.4.35.35 0 0 1-.27.16.57.57 0 0 1-.26 0h-.07a1.9 1.9 0 0 0-.4-.14 2.22 2.22 0 0 0-.45 0 2.11 2.11 0 0 0-.77.14 1.65 1.65 0 0 0-.58.39 1.62 1.62 0 0 0-.36.59 2.29 2.29 0 0 0-.12.76 2.23 2.23 0 0 0 .14.85 1.54 1.54 0 0 0 .38.59 1.62 1.62 0 0 0 .59.36 2.09 2.09 0 0 0 .72.12 3 3 0 0 0 .43 0 1.5 1.5 0 0 0 .42-.15.55.55 0 0 1 .33 0 .43.43 0 0 1 .28.16.5.5 0 0 1 .11.41.41.41 0 0 1-.24.31 2.26 2.26 0 0 1-.43.17 3.54 3.54 0 0 1-.44.11 3.44 3.44 0 0 1-.46 0 3.32 3.32 0 0 1-1.09-.18 2.62 2.62 0 0 1-.91-.54 2.58 2.58 0 0 1-.64-.9 3.24 3.24 0 0 1-.23-1.25 3 3 0 0 1 .21-1.13 2.54 2.54 0 0 1 .6-.9 2.8 2.8 0 0 1 .91-.59 3.09 3.09 0 0 1 1.19-.38Zm4.34 0a2.62 2.62 0 0 1 1.09.22 2.67 2.67 0 0 1 .86.62 2.83 2.83 0 0 1 .56.91 3.24 3.24 0 0 1 0 2.24 2.83 2.83 0 0 1-.56.91 2.67 2.67 0 0 1-.86.62 2.81 2.81 0 0 1-2.18 0 2.74 2.74 0 0 1-.85-.62 3 3 0 0 1-.57-.91 3.24 3.24 0 0 1 0-2.24 3 3 0 0 1 .57-.91 2.74 2.74 0 0 1 .85-.62 2.62 2.62 0 0 1 1.13-.26Zm0 .95a1.51 1.51 0 0 0-.87.26 1.73 1.73 0 0 0-.59.69 2.16 2.16 0 0 0-.21 1 2.19 2.19 0 0 0 .21 1 1.73 1.73 0 0 0 .59.69 1.59 1.59 0 0 0 1.74 0 1.73 1.73 0 0 0 .59-.69 2.25 2.25 0 0 0 0-1.94 1.73 1.73 0 0 0-.59-.69 1.51 1.51 0 0 0-.83-.36Z"
                                            />
                                        </g>
                                    </g>
                                    <g id="room_area-10" data-name="room/area">
                                        <g
                                            id="Icons_Roomtype_r-shared-space-haagse"
                                            data-name="Icons/Roomtype/r-shared-space-haagse"
                                        >
                                            <path
                                                id="Combined-Shape-13"
                                                data-name="Combined-Shape"
                                                className="cls-6"
                                                d="M794.5 145a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H817l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L815.5 146h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L796 145Z"
                                            />
                                        </g>
                                    </g>
                                    <g id="room_area-11" data-name="room/area">
                                        <circle
                                            id="Oval-10"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.10"].tables
                                                        ?.Table4 ?? "0"
                                                )
                                            )}
                                            cx={946.5}
                                            cy={147.5}
                                            r={21.5}
                                        />
                                        <g id="Group-27" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-20"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-14"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M934.5 136a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H957l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L955.5 137h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L936 136Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-12" data-name="room/area">
                                        <circle
                                            id="Oval-11"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.10"].tables
                                                        ?.Table2 ?? "0"
                                                )
                                            )}
                                            cx={865.5}
                                            cy={203.5}
                                            r={21.5}
                                        />
                                        <g id="Group-29" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-21"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-15"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M853.5 192a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H876l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L874.5 193h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L855 192Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-13" data-name="room/area">
                                        <circle
                                            id="Oval-12"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.10"].tables
                                                        ?.Table6 ?? "0"
                                                )
                                            )}
                                            cx={1017.5}
                                            cy={115.5}
                                            r={21.5}
                                        />
                                        <g id="Group-31" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-22"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-16"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M1005.5 104a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H1028l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L1026.5 105h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L1007 104Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-14" data-name="room/area">
                                        <circle
                                            id="Oval-13"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.10"].tables
                                                        ?.Table5 ?? "0"
                                                )
                                            )}
                                            cx={986.5}
                                            cy={55.5}
                                            r={21.5}
                                        />
                                        <g id="Group-33" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-23"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-17"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M974.5 44a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H997l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L995.5 45h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L976 44Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-15" data-name="room/area">
                                        <circle
                                            id="Oval-14"
                                            data-name="Oval"
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.10"].tables
                                                        ?.Table1 ?? "0"
                                                )
                                            )}
                                            cx={805.5}
                                            cy={155.5}
                                            r={21.5}
                                        />
                                        <g id="Group-35" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-24"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-18"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M793.5 144a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H816l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L814.5 145h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L795 144Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="room_area-16" data-name="room/area">
                                        <circle
                                            id="Oval-15"
                                            data-name="Oval"
                                            cx={894.5}
                                            cy={105.5}
                                            r={21.5}
                                            fill={colorOccupance(
                                                parseInt(
                                                    currentData?.["0.10"].tables
                                                        ?.Table3 ?? "0"
                                                )
                                            )}
                                        />
                                        <g id="Group-37" data-name="Group">
                                            <g
                                                id="Icons_Roomtype_r-storage-25"
                                                data-name="Icons/Roomtype/r-storage"
                                            >
                                                <path
                                                    id="Combined-Shape-19"
                                                    data-name="Combined-Shape"
                                                    className="cls-6"
                                                    d="M882.5 94a.5.5 0 0 1-.09-1h24.09a.5.5 0 0 1 .09 1H905l1.94 15.5a.44.44 0 0 1-.39.5.59.59 0 0 1-.58-.49L903.5 95h-18l-2.42 14.51a.59.59 0 0 1-.58.49.44.44 0 0 1-.44-.44v-.06L884 94Z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="classroom-36">
                                    <path
                                        id="room-area-11"
                                        data-name="room-area"
                                        fill={colorOccupance(
                                            parseInt(
                                                currentData?.["0.34"]
                                                    .occupancy ?? "0"
                                            )
                                        )}
                                        d="M99.5 244h106v110h-106z"
                                    />
                                    <g
                                        id="Icons_Roomtype_r-storage-26"
                                        data-name="Icons/Roomtype/r-storage"
                                    >
                                        <path
                                            id="i-r-shared"
                                            className="cls-6"
                                            d="M146.5 291.5a.5.5 0 0 1 .09 1H141V311h11.5v-11a.5.5 0 0 1 .41-.49H160a.5.5 0 0 1 .09 1h-6.59v11a.5.5 0 0 1-.41.49H140.5a.5.5 0 0 1-.49-.41V292a.5.5 0 0 1 .41-.49h6.09Zm16.56 4.17a1 1 0 0 1 1.93.43v.12l-2 9v.12a1 1 0 0 1-.82.65h-.22l1.09 5.4v.09a.5.5 0 0 1-.4.5.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76L156 311.6v.08a.49.49 0 0 1-.47.32h-.18a.49.49 0 0 1-.31-.47v-.09l1.09-5.4h-.72a1 1 0 0 1 0-2h5.82l1.82-8.22ZM151.72 287a1 1 0 0 1 .77 1.08v.12l-2 9a1 1 0 0 1-.86.77h-.22l1.09 5.4a.51.51 0 0 1-.39.59.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76l-1.13 5.6a.5.5 0 0 1-.5.4h-.09a.5.5 0 0 1-.4-.5v-.09l1.09-5.4H143a1 1 0 0 1-.12-2h5.82l1.82-8.22a1 1 0 0 1 1.2-.76Z"
                                        />
                                    </g>
                                    <g id="Group-39" data-name="Group">
                                        <path
                                            id="Rectangle-13"
                                            data-name="Rectangle"
                                            className="cls-3"
                                            d="M99.5 244h41v26h-41z"
                                        />
                                        <g
                                            id="_0.36-6"
                                            data-name={0.36}
                                            className="cls-9"
                                        >
                                            <text
                                                className="cls-10"
                                                transform="translate(107.96 261)"
                                            >
                                                {"0.34"}
                                            </text>
                                        </g>
                                    </g>

                                    <g
                                        id="Icons_Sensor-type_s-tem-7"
                                        data-name="Icons/Sensor-type/s-tem"
                                    >
                                        <path
                                            id="i-s-temperature-4"
                                            data-name="i-s-temperature"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.34"]
                                                            .temp ?? "0"
                                                    ),
                                                    22,
                                                    19
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M155 251a.72.72 0 0 1 .72.72v1.2h4.08a.24.24 0 0 1 0 .48h-4.08v1h2.64a.24.24 0 1 1 0 .48h-2.64v4a2.17 2.17 0 0 1 1.44 1.94v.1a2.16 2.16 0 1 1-2.88-2v-7.08a.72.72 0 0 1 .72-.84Z"
                                        />
                                        <path
                                            id="Combined-Shape-20"
                                            data-name="Combined-Shape"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.34"]
                                                            .humidity ?? "0"
                                                    ),
                                                    60,
                                                    40
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M170.6 255.86a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Zm5.28-4.8a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Z"
                                        />
                                        <path
                                            id="i-s-co2-3"
                                            data-name="i-s-co2"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.34"]
                                                            .co2 ?? "0"
                                                    ),
                                                    1200
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M196.33 258.81a1.09 1.09 0 0 1 .58.14 1 1 0 0 1 .38.38 1.12 1.12 0 0 1 .13.55 1.29 1.29 0 0 1-.07.43 2.3 2.3 0 0 1-.15.32l-.06.1-.1.14-.14.17-.07.09-.64.67h1.08a.28.28 0 0 1 .15.08.28.28 0 0 1 .08.2.27.27 0 0 1-.08.19.21.21 0 0 1-.15.07h-1.8a.21.21 0 0 1-.15-.07.3.3 0 0 1-.07-.21.31.31 0 0 1 .05-.18l1.08-1.16.09-.1a1.21 1.21 0 0 0 .2-.3.82.82 0 0 0 .11-.37.53.53 0 0 0-.07-.28.64.64 0 0 0-.2-.2.47.47 0 0 0-.26-.08.59.59 0 0 0-.29.1 1.1 1.1 0 0 0-.27.26.27.27 0 0 1-.12.1h-.11a.32.32 0 0 1-.2-.08.26.26 0 0 1 0-.36 1.77 1.77 0 0 1 .14-.17 1.71 1.71 0 0 1 .44-.3 1.08 1.08 0 0 1 .49-.13Zm-8-4.58a2.61 2.61 0 0 1 .69.08 2.8 2.8 0 0 1 .63.23.41.41 0 0 1 .24.33.46.46 0 0 1-.11.4.35.35 0 0 1-.27.16.57.57 0 0 1-.26 0h-.07a1.9 1.9 0 0 0-.4-.14 2.22 2.22 0 0 0-.45 0 2.11 2.11 0 0 0-.77.14 1.65 1.65 0 0 0-.58.39 1.62 1.62 0 0 0-.36.59 2.29 2.29 0 0 0-.12.76 2.23 2.23 0 0 0 .14.85 1.54 1.54 0 0 0 .38.59 1.62 1.62 0 0 0 .59.36 2.09 2.09 0 0 0 .72.12 3 3 0 0 0 .43 0 1.5 1.5 0 0 0 .42-.15.55.55 0 0 1 .33-.05.43.43 0 0 1 .28.16.5.5 0 0 1 .11.41.41.41 0 0 1-.24.31 2.26 2.26 0 0 1-.43.17 3.54 3.54 0 0 1-.44.11 3.44 3.44 0 0 1-.46 0 3.32 3.32 0 0 1-1.09-.18 2.62 2.62 0 0 1-.91-.54 2.58 2.58 0 0 1-.64-.9 3.24 3.24 0 0 1-.23-1.25 3 3 0 0 1 .21-1.13 2.54 2.54 0 0 1 .6-.9 2.8 2.8 0 0 1 .91-.59 3.09 3.09 0 0 1 1.19-.33Zm4.34 0a2.62 2.62 0 0 1 1.09.22 2.67 2.67 0 0 1 .86.62 2.83 2.83 0 0 1 .56.91 3.24 3.24 0 0 1 0 2.24 2.83 2.83 0 0 1-.56.91 2.67 2.67 0 0 1-.86.62 2.81 2.81 0 0 1-2.18 0 2.74 2.74 0 0 1-.85-.62 3 3 0 0 1-.57-.91 3.24 3.24 0 0 1 0-2.24 3 3 0 0 1 .57-.91 2.74 2.74 0 0 1 .85-.62 2.62 2.62 0 0 1 1.13-.26Zm0 .95a1.51 1.51 0 0 0-.87.26 1.73 1.73 0 0 0-.59.69 2.16 2.16 0 0 0-.21 1 2.19 2.19 0 0 0 .21 1 1.73 1.73 0 0 0 .59.69 1.59 1.59 0 0 0 1.74 0 1.73 1.73 0 0 0 .59-.69 2.25 2.25 0 0 0 0-1.94 1.73 1.73 0 0 0-.59-.69 1.51 1.51 0 0 0-.83-.36Z"
                                        />
                                    </g>
                                </g>
                                <g id="classroom-32">
                                    <path
                                        id="room-area-12"
                                        data-name="room-area"
                                        fill={colorOccupance(
                                            parseInt(
                                                currentData?.["0.30"]
                                                    .occupancy ?? "0"
                                            )
                                        )}
                                        d="M207 244h106v110H207z"
                                    />
                                    <g
                                        id="Icons_Roomtype_r-storage-27"
                                        data-name="Icons/Roomtype/r-storage"
                                    >
                                        <path
                                            id="i-r-shared-2"
                                            data-name="i-r-shared"
                                            className="cls-6"
                                            d="M254 291.5a.5.5 0 0 1 .09 1h-5.59V311H260v-11a.5.5 0 0 1 .41-.49h7.09a.5.5 0 0 1 .09 1H261v11a.5.5 0 0 1-.41.49H248a.5.5 0 0 1-.49-.41V292a.5.5 0 0 1 .41-.49H254Zm16.56 4.17a1 1 0 0 1 1.93.43v.12l-2 9v.12a1 1 0 0 1-.82.65h-.22l1.09 5.4v.09a.5.5 0 0 1-.4.5.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76l-1.13 5.6v.08a.49.49 0 0 1-.47.32h-.18a.49.49 0 0 1-.31-.47v-.09l1.09-5.4h-.72a1 1 0 0 1 0-2h5.82l1.82-8.22ZM259.22 287a1 1 0 0 1 .77 1.08v.12l-2 9a1 1 0 0 1-.86.77h-.22l1.09 5.4a.51.51 0 0 1-.39.59.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76L251 303.6a.5.5 0 0 1-.5.4h-.09a.5.5 0 0 1-.4-.5v-.09l1.09-5.4h-.6a1 1 0 0 1-.12-2h5.82l1.82-8.22a1 1 0 0 1 1.2-.79Z"
                                        />
                                    </g>
                                    <g
                                        id="room_number-8"
                                        data-name="room/number"
                                    >
                                        <path
                                            id="Rectangle-14"
                                            data-name="Rectangle"
                                            className="cls-3"
                                            d="M207 244h41v26h-41z"
                                        />
                                        <g
                                            id="_0.36-7"
                                            data-name={0.36}
                                            className="cls-9"
                                        >
                                            <text
                                                className="cls-10"
                                                transform="translate(215.46 261)"
                                            >
                                                {"0.30"}
                                            </text>
                                        </g>
                                    </g>

                                    <g
                                        id="room_sensors-3"
                                        data-name="room/sensors"
                                    >
                                        <g
                                            id="Icons_Sensor-type_s-tem-8"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="i-s-temperature-5"
                                                data-name="i-s-temperature"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.30"
                                                            ].temp ?? "0"
                                                        ),
                                                        22,
                                                        19
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M262.52 251a.72.72 0 0 1 .72.72v1.2h4.08a.24.24 0 0 1 0 .48h-4.08v1h2.64a.24.24 0 1 1 0 .48h-2.64v4a2.17 2.17 0 0 1 1.44 1.94v.1a2.16 2.16 0 1 1-2.88-2v-7.08a.72.72 0 0 1 .72-.84Z"
                                            />
                                        </g>
                                        <g
                                            id="Icons_Sensor-type_s-tem-9"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="Combined-Shape-21"
                                                data-name="Combined-Shape"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.30"
                                                            ].humidity ?? "0"
                                                        ),
                                                        60,
                                                        40
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M278.1 255.86a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 0 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25s.02.01.04 0Zm5.28-4.8a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Z"
                                            />
                                        </g>
                                        <g
                                            id="Icons_Sensor-type_s-tem-10"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="i-s-co2-4"
                                                data-name="i-s-co2"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.30"
                                                            ].co2 ?? "0"
                                                        ),
                                                        1200
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M303.83 258.81a1.09 1.09 0 0 1 .58.14 1 1 0 0 1 .38.38 1.12 1.12 0 0 1 .13.55 1.29 1.29 0 0 1-.07.43 2.3 2.3 0 0 1-.15.32l-.06.1-.1.14-.14.17-.07.09-.64.67h1.08a.28.28 0 0 1 .15.08.28.28 0 0 1 .08.2.27.27 0 0 1-.08.19.21.21 0 0 1-.15.07H303a.21.21 0 0 1-.15-.07.3.3 0 0 1-.07-.21.31.31 0 0 1 .05-.18l1.08-1.16.09-.1a1.21 1.21 0 0 0 .2-.3.82.82 0 0 0 .11-.37.53.53 0 0 0-.07-.28.64.64 0 0 0-.2-.2.47.47 0 0 0-.26-.08.59.59 0 0 0-.29.1 1.1 1.1 0 0 0-.27.26.27.27 0 0 1-.12.1h-.11a.32.32 0 0 1-.2-.08.26.26 0 0 1 0-.36 1.77 1.77 0 0 1 .14-.17 1.71 1.71 0 0 1 .44-.3 1.08 1.08 0 0 1 .46-.13Zm-8-4.58a2.61 2.61 0 0 1 .69.08 2.8 2.8 0 0 1 .63.23.41.41 0 0 1 .24.33.46.46 0 0 1-.11.4.35.35 0 0 1-.27.16.57.57 0 0 1-.26 0h-.07a1.9 1.9 0 0 0-.4-.14 2.22 2.22 0 0 0-.45 0 2.11 2.11 0 0 0-.77.14 1.65 1.65 0 0 0-.58.39 1.62 1.62 0 0 0-.36.59 2.29 2.29 0 0 0-.12.76 2.23 2.23 0 0 0 .14.85 1.54 1.54 0 0 0 .38.59 1.62 1.62 0 0 0 .59.36 2.09 2.09 0 0 0 .72.12 3 3 0 0 0 .43 0 1.5 1.5 0 0 0 .42-.15.55.55 0 0 1 .33-.05.43.43 0 0 1 .28.16.5.5 0 0 1 .11.41.41.41 0 0 1-.24.31 2.26 2.26 0 0 1-.43.17 3.54 3.54 0 0 1-.44.11 3.44 3.44 0 0 1-.46 0 3.32 3.32 0 0 1-1.09-.18 2.62 2.62 0 0 1-.91-.54 2.58 2.58 0 0 1-.64-.9 3.24 3.24 0 0 1-.23-1.25 3 3 0 0 1 .21-1.13 2.54 2.54 0 0 1 .6-.9 2.8 2.8 0 0 1 .91-.59 3.09 3.09 0 0 1 1.19-.33Zm4.34 0a2.62 2.62 0 0 1 1.09.22 2.67 2.67 0 0 1 .86.62 2.83 2.83 0 0 1 .56.91 3.24 3.24 0 0 1 0 2.24 2.83 2.83 0 0 1-.56.91 2.67 2.67 0 0 1-.86.62 2.81 2.81 0 0 1-2.18 0 2.74 2.74 0 0 1-.85-.62 3 3 0 0 1-.57-.91 3.24 3.24 0 0 1 0-2.24 3 3 0 0 1 .57-.91 2.74 2.74 0 0 1 .85-.62 2.62 2.62 0 0 1 1.13-.26Zm0 .95a1.51 1.51 0 0 0-.87.26 1.73 1.73 0 0 0-.59.69 2.16 2.16 0 0 0-.21 1 2.19 2.19 0 0 0 .21 1 1.73 1.73 0 0 0 .59.69 1.59 1.59 0 0 0 1.74 0 1.73 1.73 0 0 0 .59-.69 2.25 2.25 0 0 0 0-1.94 1.73 1.73 0 0 0-.59-.69 1.51 1.51 0 0 0-.83-.36Z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g id="classroom-28">
                                    <path
                                        id="room-area-13"
                                        data-name="room-area"
                                        fill={colorOccupance(
                                            parseInt(
                                                currentData?.["0.26"]
                                                    .occupancy ?? "0"
                                            )
                                        )}
                                        d="M315 244h104v110H315z"
                                    />
                                    <g
                                        id="Icons_Roomtype_r-storage-28"
                                        data-name="Icons/Roomtype/r-storage"
                                    >
                                        <path
                                            id="i-r-shared-3"
                                            data-name="i-r-shared"
                                            className="cls-6"
                                            d="M361 291.5a.5.5 0 0 1 .09 1h-5.59V311H367v-11a.5.5 0 0 1 .41-.49h7.09a.5.5 0 0 1 .09 1H368v11a.5.5 0 0 1-.41.49H355a.5.5 0 0 1-.49-.41V292a.5.5 0 0 1 .41-.49H361Zm16.56 4.17a1 1 0 0 1 1.93.43v.12l-2 9v.12a1 1 0 0 1-.82.65h-.22l1.09 5.4v.09a.5.5 0 0 1-.4.5.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76l-1.13 5.6v.08a.49.49 0 0 1-.47.32h-.18a.49.49 0 0 1-.31-.47v-.09l1.09-5.4h-.72a1 1 0 0 1 0-2h5.82l1.82-8.22ZM366.22 287a1 1 0 0 1 .77 1.08v.12l-2 9a1 1 0 0 1-.86.77h-.22l1.09 5.4a.51.51 0 0 1-.39.59.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76L358 303.6a.5.5 0 0 1-.5.4h-.09a.5.5 0 0 1-.4-.5v-.09l1.09-5.4h-.6a1 1 0 0 1-.12-2h5.82l1.82-8.22a1 1 0 0 1 1.2-.79Z"
                                        />
                                    </g>
                                    <g
                                        id="room_number-9"
                                        data-name="room/number"
                                    >
                                        <path
                                            id="Rectangle-15"
                                            data-name="Rectangle"
                                            className="cls-3"
                                            d="M315 244h41v26h-41z"
                                        />
                                        <g
                                            id="_0.36-8"
                                            data-name={0.36}
                                            className="cls-9"
                                        >
                                            <text
                                                className="cls-10"
                                                transform="translate(323.46 261)"
                                            >
                                                {"0.26"}
                                            </text>
                                        </g>
                                    </g>
                                    <g
                                        id="Icons_Sensor-type_s-tem-11"
                                        data-name="Icons/Sensor-type/s-tem"
                                    >
                                        <path
                                            id="i-s-temperature-6"
                                            data-name="i-s-temperature"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.26"]
                                                            .temp ?? "0"
                                                    ),
                                                    22,
                                                    19
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M368.52 251a.72.72 0 0 1 .72.72v1.2h4.08a.24.24 0 0 1 0 .48h-4.08v1h2.64a.24.24 0 1 1 0 .48h-2.64v4a2.17 2.17 0 0 1 1.44 1.94v.1a2.16 2.16 0 1 1-2.88-2v-7.08a.72.72 0 0 1 .72-.84Z"
                                        />
                                        <path
                                            id="Combined-Shape-22"
                                            data-name="Combined-Shape"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.26"]
                                                            .humidity ?? "0"
                                                    ),
                                                    60,
                                                    40
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M384.1 255.86a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25s.02.01.04 0Zm5.28-4.8a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Z"
                                        />
                                        <path
                                            id="i-s-co2-5"
                                            data-name="i-s-co2"
                                            style={{
                                                fill: inRange(
                                                    parseInt(
                                                        currentData?.["0.26"]
                                                            .co2 ?? "0"
                                                    ),
                                                    1200
                                                )
                                                    ? "var(--gray-primary)"
                                                    : "var(--red-primary)",
                                            }}
                                            d="M409.83 258.81a1.09 1.09 0 0 1 .58.14 1 1 0 0 1 .38.38 1.12 1.12 0 0 1 .13.55 1.29 1.29 0 0 1-.07.43 2.3 2.3 0 0 1-.15.32l-.06.1-.1.14-.14.17-.07.09-.64.67h1.08a.28.28 0 0 1 .15.08.28.28 0 0 1 .08.2.27.27 0 0 1-.08.19.21.21 0 0 1-.15.07H409a.21.21 0 0 1-.15-.07.3.3 0 0 1-.07-.21.31.31 0 0 1 .05-.18l1.08-1.16.09-.1a1.21 1.21 0 0 0 .2-.3.82.82 0 0 0 .11-.37.53.53 0 0 0-.07-.28.64.64 0 0 0-.2-.2.47.47 0 0 0-.26-.08.59.59 0 0 0-.29.1 1.1 1.1 0 0 0-.27.26.27.27 0 0 1-.12.1h-.11a.32.32 0 0 1-.2-.08.26.26 0 0 1 0-.36 1.77 1.77 0 0 1 .14-.17 1.71 1.71 0 0 1 .44-.3 1.08 1.08 0 0 1 .46-.13Zm-8-4.58a2.61 2.61 0 0 1 .69.08 2.8 2.8 0 0 1 .63.23.41.41 0 0 1 .24.33.46.46 0 0 1-.11.4.35.35 0 0 1-.27.16.57.57 0 0 1-.26 0h-.07a1.9 1.9 0 0 0-.4-.14 2.22 2.22 0 0 0-.45 0 2.11 2.11 0 0 0-.77.14 1.65 1.65 0 0 0-.58.39 1.62 1.62 0 0 0-.36.59 2.29 2.29 0 0 0-.12.76 2.23 2.23 0 0 0 .14.85 1.54 1.54 0 0 0 .38.59 1.62 1.62 0 0 0 .59.36 2.09 2.09 0 0 0 .72.12 3 3 0 0 0 .43 0 1.5 1.5 0 0 0 .42-.15.55.55 0 0 1 .33-.05.43.43 0 0 1 .28.16.5.5 0 0 1 .11.41.41.41 0 0 1-.24.31 2.26 2.26 0 0 1-.43.17 3.54 3.54 0 0 1-.44.11 3.44 3.44 0 0 1-.46 0 3.32 3.32 0 0 1-1.09-.18 2.62 2.62 0 0 1-.91-.54 2.58 2.58 0 0 1-.64-.9 3.24 3.24 0 0 1-.23-1.25 3 3 0 0 1 .21-1.13 2.54 2.54 0 0 1 .6-.9 2.8 2.8 0 0 1 .91-.59 3.09 3.09 0 0 1 1.19-.33Zm4.34 0a2.62 2.62 0 0 1 1.09.22 2.67 2.67 0 0 1 .86.62 2.83 2.83 0 0 1 .56.91 3.24 3.24 0 0 1 0 2.24 2.83 2.83 0 0 1-.56.91 2.67 2.67 0 0 1-.86.62 2.81 2.81 0 0 1-2.18 0 2.74 2.74 0 0 1-.85-.62 3 3 0 0 1-.57-.91 3.24 3.24 0 0 1 0-2.24 3 3 0 0 1 .57-.91 2.74 2.74 0 0 1 .85-.62 2.62 2.62 0 0 1 1.13-.26Zm0 .95a1.51 1.51 0 0 0-.87.26 1.73 1.73 0 0 0-.59.69 2.16 2.16 0 0 0-.21 1 2.19 2.19 0 0 0 .21 1 1.73 1.73 0 0 0 .59.69 1.59 1.59 0 0 0 1.74 0 1.73 1.73 0 0 0 .59-.69 2.25 2.25 0 0 0 0-1.94 1.73 1.73 0 0 0-.59-.69 1.51 1.51 0 0 0-.83-.36Z"
                                        />
                                    </g>
                                </g>
                                <g id="classroom-20">
                                    <path
                                        id="room-area-14"
                                        data-name="room-area"
                                        fill={colorOccupance(
                                            parseInt(
                                                currentData?.["0.22"]
                                                    .occupancy ?? "0"
                                            )
                                        )}
                                        d="M420 244h131v110H420z"
                                    />
                                    <g
                                        id="Icons_Roomtype_r-storage-29"
                                        data-name="Icons/Roomtype/r-storage"
                                    >
                                        <path
                                            id="i-r-shared-4"
                                            data-name="i-r-shared"
                                            className="cls-6"
                                            d="M479.5 291.5a.5.5 0 0 1 .09 1H474V311h11.5v-11a.5.5 0 0 1 .41-.49H493a.5.5 0 0 1 .09 1h-6.59v11a.5.5 0 0 1-.41.49H473.5a.5.5 0 0 1-.49-.41V292a.5.5 0 0 1 .41-.49h6.09Zm16.56 4.17a1 1 0 0 1 1.93.43v.12l-2 9v.12a1 1 0 0 1-.82.65h-.22l1.09 5.4v.09a.5.5 0 0 1-.4.5.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76L489 311.6v.08a.49.49 0 0 1-.47.32h-.18a.49.49 0 0 1-.31-.47v-.09l1.09-5.4h-.72a1 1 0 0 1 0-2h5.82l1.82-8.22ZM484.72 287a1 1 0 0 1 .77 1.08v.12l-2 9a1 1 0 0 1-.86.77h-.22l1.09 5.4a.51.51 0 0 1-.39.59.49.49 0 0 1-.56-.31v-.08l-1.13-5.6h-3.76l-1.13 5.6a.5.5 0 0 1-.5.4h-.09a.5.5 0 0 1-.4-.5v-.09l1.09-5.4H476a1 1 0 0 1-.12-2h5.82l1.82-8.22a1 1 0 0 1 1.2-.76Z"
                                        />
                                    </g>
                                    <g
                                        id="room_number-10"
                                        data-name="room/number"
                                    >
                                        <path
                                            id="Rectangle-16"
                                            data-name="Rectangle"
                                            className="cls-3"
                                            d="M420 244h41v26h-41z"
                                        />
                                        <g
                                            id="_0.36-9"
                                            data-name={0.36}
                                            className="cls-9"
                                        >
                                            <text
                                                className="cls-10"
                                                transform="translate(428 261)"
                                            >
                                                {"0.22"}
                                            </text>
                                        </g>
                                    </g>
                                    <g
                                        id="room_sensors-4"
                                        data-name="room/sensors"
                                    >
                                        <g
                                            id="Icons_Sensor-type_s-tem-12"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="i-s-temperature-7"
                                                data-name="i-s-temperature"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.22"
                                                            ].temp ?? "0"
                                                        ),
                                                        22,
                                                        19
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M500.52 251a.72.72 0 0 1 .72.72v1.2h4.08a.24.24 0 0 1 0 .48h-4.08v1h2.64a.24.24 0 1 1 0 .48h-2.64v4a2.17 2.17 0 0 1 1.44 1.94v.1a2.16 2.16 0 1 1-2.88-2v-7.08a.72.72 0 0 1 .72-.84Z"
                                            />
                                        </g>
                                        <g
                                            id="Icons_Sensor-type_s-tem-13"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="Combined-Shape-23"
                                                data-name="Combined-Shape"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.22"
                                                            ].humidity ?? "0"
                                                        ),
                                                        60,
                                                        40
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M516.1 255.86a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 0 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Zm5.28-4.8a.27.27 0 0 1 .39 0l.1.13.09.12.18.23.09.12.17.22.17.23.15.21.15.22.14.2.07.1.13.2.12.2.11.18a4.49 4.49 0 0 1 .76 2.09 2.65 2.65 0 1 1-5.3 0 4.52 4.52 0 0 1 .77-2.09l.11-.18.12-.2.13-.2.07-.1.14-.2.14-.22.16-.21.16-.23.09-.11.17-.23.19-.23.19-.25Z"
                                            />
                                        </g>
                                        <g
                                            id="Icons_Sensor-type_s-tem-14"
                                            data-name="Icons/Sensor-type/s-tem"
                                        >
                                            <path
                                                id="i-s-co2-6"
                                                data-name="i-s-co2"
                                                style={{
                                                    fill: inRange(
                                                        parseInt(
                                                            currentData?.[
                                                                "0.22"
                                                            ].co2 ?? "0"
                                                        ),
                                                        1200
                                                    )
                                                        ? "var(--gray-primary)"
                                                        : "var(--red-primary)",
                                                }}
                                                d="M541.83 258.81a1.09 1.09 0 0 1 .58.14 1 1 0 0 1 .38.38 1.12 1.12 0 0 1 .13.55 1.29 1.29 0 0 1-.07.43 2.3 2.3 0 0 1-.15.32l-.06.1-.1.14-.14.17-.07.09-.64.67h1.08a.28.28 0 0 1 .15.08.28.28 0 0 1 .08.2.27.27 0 0 1-.08.19.21.21 0 0 1-.15.07H541a.21.21 0 0 1-.15-.07.3.3 0 0 1-.07-.21.31.31 0 0 1 0-.18l1.08-1.16.09-.1a1.21 1.21 0 0 0 .2-.3.82.82 0 0 0 .11-.37.53.53 0 0 0-.07-.28.64.64 0 0 0-.2-.2.47.47 0 0 0-.26-.08.59.59 0 0 0-.29.1 1.1 1.1 0 0 0-.27.26.27.27 0 0 1-.12.1h-.11a.32.32 0 0 1-.2-.08.26.26 0 0 1 0-.36 1.77 1.77 0 0 1 .14-.17 1.71 1.71 0 0 1 .44-.3 1.08 1.08 0 0 1 .51-.13Zm-8-4.58a2.61 2.61 0 0 1 .69.08 2.8 2.8 0 0 1 .63.23.41.41 0 0 1 .24.33.46.46 0 0 1-.11.4.35.35 0 0 1-.27.16.57.57 0 0 1-.26 0h-.07a1.9 1.9 0 0 0-.4-.14 2.22 2.22 0 0 0-.45 0 2.11 2.11 0 0 0-.77.14 1.65 1.65 0 0 0-.58.39 1.62 1.62 0 0 0-.36.59 2.29 2.29 0 0 0-.12.76 2.23 2.23 0 0 0 .14.85 1.54 1.54 0 0 0 .38.59 1.62 1.62 0 0 0 .59.36 2.09 2.09 0 0 0 .72.12 3 3 0 0 0 .43 0 1.5 1.5 0 0 0 .42-.15.55.55 0 0 1 .33-.05.43.43 0 0 1 .28.16.5.5 0 0 1 .11.41.41.41 0 0 1-.24.31 2.26 2.26 0 0 1-.43.17 3.54 3.54 0 0 1-.44.11 3.44 3.44 0 0 1-.46 0 3.32 3.32 0 0 1-1.09-.18 2.62 2.62 0 0 1-.91-.54 2.58 2.58 0 0 1-.64-.9 3.24 3.24 0 0 1-.23-1.25 3 3 0 0 1 .21-1.13 2.54 2.54 0 0 1 .6-.9 2.8 2.8 0 0 1 .91-.59 3.09 3.09 0 0 1 1.19-.33Zm4.34 0a2.62 2.62 0 0 1 1.09.22 2.67 2.67 0 0 1 .86.62 2.83 2.83 0 0 1 .56.91 3.24 3.24 0 0 1 0 2.24 2.83 2.83 0 0 1-.56.91 2.67 2.67 0 0 1-.86.62 2.81 2.81 0 0 1-2.18 0 2.74 2.74 0 0 1-.85-.62 3 3 0 0 1-.57-.91 3.24 3.24 0 0 1 0-2.24 3 3 0 0 1 .57-.91 2.74 2.74 0 0 1 .85-.62 2.62 2.62 0 0 1 1.13-.26Zm0 .95a1.51 1.51 0 0 0-.87.26 1.73 1.73 0 0 0-.59.69 2.16 2.16 0 0 0-.21 1 2.19 2.19 0 0 0 .21 1 1.73 1.73 0 0 0 .59.69 1.59 1.59 0 0 0 1.74 0 1.73 1.73 0 0 0 .59-.69 2.25 2.25 0 0 0 0-1.94 1.73 1.73 0 0 0-.59-.69 1.51 1.51 0 0 0-.83-.36Z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g id="static-img-plan-top">
                                    <path
                                        id="outer-wall-shade"
                                        style={{
                                            strokeWidth: 4,
                                            stroke: "#4e5c69",
                                            fill: "none",
                                        }}
                                        d="M26.21 362.85v-6.7H2V241.32l627.5.18-13-21.5L742 154 1002.5 3.5l136.59 219.75-27.97 16.45 50.58 127.1-9.51 4.01L1164 423.3l-62.68 45.68-55.03 22.01L940.58 502H2v-85.11h24.21v-7.81"
                                    />
                                    <path
                                        id="outer-wall"
                                        style={{
                                            stroke: "#223343",
                                            strokeWidth: 4,
                                            fill: "none",
                                        }}
                                        d="M27.21 362.85v-7.7H3V242.32h627.88l-13.21-22.02 123.81-64.94L1002.32 4l135.37 219.05-28.07 15.97 50.63 127.13-9.36 3.86 12.11 52.84-62.18 45.13-55.03 22.01L939.58 501H3v-83.11h24.21v-8.81"
                                    />
                                    <path
                                        id="inner-walls"
                                        d="M27.21 355.15H180.19m14.31 0H216.51m13.76 0h89.19m14.31 0H469M389 388h80v-32.85h51.82m17.61 0h200.85m11.56 0h73.73-55v-33.52h28.61m11.56 0h14.85V239l-7.59-7.51m-16.2-16-60.37-59.59m84.14 83.1h9.91m14.31 0h23.66v27.51m0 10.46v5.51m0-43.48h237.17m-30.26-110.08-84.75 51.73v19.82m0 11V239h-73.18v43.5h-49v28.62m0 10.45v3.86h49-49v31.92h49v61.09h18.7V501m-90.79 0v-82 3.85h-11m-11.55 0h-9.36V419H764v3.85h-9.35m-11.56 0h-6.6v-3.3H624.82v-25.33H522.47v25.32H487.8v-2.75h-50.08v2.75h-108.4v18.72m0 43.48V501m-218.46 0v-82.56H75.64"
                                        style={{
                                            strokeWidth: 2,
                                            stroke: "#223343",
                                            fill: "none",
                                        }}
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </article>

            <Legend />
        </>
    )
}
