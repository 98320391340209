import { useEffect, useState } from "react"
import { DataCol } from "./DataCol/DataCol"
import { Icon } from "../Icon/Icons"
import { useLocation } from "react-router-dom"
import { spacesDataInterface } from "../../helpers/updateData"
import EmblaCarousel from "./EmblaCarousel"

import styles from "./Table.module.scss"

export const Table: React.FunctionComponent<{
    columnsData: spacesDataInterface | undefined
}> = ({ columnsData }) => {
    const [currentColumns, setCurrentColumns] = useState<spacesDataInterface>()
    const location = useLocation()
    useEffect(() => {
        columnsData && setCurrentColumns(columnsData)
    }, [columnsData])
    return (
        <article className={styles.table_container}>
            <header>
                <span>
                    <Icon
                        customStyle={{ display: "flex", alignItems: "center" }}
                        icon="occupancy"
                    />
                </span>
                <span>
                    <Icon
                        customStyle={{ display: "flex", alignItems: "center" }}
                        icon="temp"
                    />
                </span>
                <span>
                    <Icon
                        customStyle={{ display: "flex", alignItems: "center" }}
                        icon="humid"
                    />
                </span>
                <span>
                    <Icon
                        customStyle={{ display: "flex", alignItems: "center" }}
                        icon="co2"
                    />
                </span>
            </header>

            <ul>{columnsData && <EmblaCarousel columns={currentColumns} />}</ul>
        </article>
    )
}
