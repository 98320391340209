import useEmblaCarousel from "embla-carousel-react"
import { EmblaOptionsType } from "embla-carousel"
import styles from "./Table.module.scss"
import { DataCol } from "./DataCol/DataCol"
import { spacesDataInterface } from "../../helpers/updateData"
import Autoplay from "embla-carousel-autoplay"

interface Props {
    columns?: spacesDataInterface
}

const EmblaCarousel = ({ columns }: Props) => {
    const autoplay = Autoplay({ delay: 4000 })
    const options: EmblaOptionsType = {
        loop: true,
        slidesToScroll: 1,
        align: 0,
        containScroll: "trimSnaps",
        speed: 10,
    }
    const [emblaRef] = useEmblaCarousel(options, [autoplay])

    return (
        <div className={styles.embla} ref={emblaRef}>
            <div className={styles.embla__container}>
                {columns &&
                    Object.keys(columns)
                        .slice(0, 10)
                        .map((key) => {
                            let col = columns[key]
                            return (
                                <div className={styles.embla__slide} key={key}>
                                    <DataCol key={key} data={{ [key]: col }} />
                                </div>
                            )
                        })}
            </div>
        </div>
    )
}

export default EmblaCarousel
