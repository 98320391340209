import styles from "./Legend.module.scss"

export const Legend = () => {
    return (
        <article className={styles.legend_container}>
            <ul>
                <li>Chill</li>
                <li>Lively</li>
                <li>Busy</li>
            </ul>
        </article>
    )
}
